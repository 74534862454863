.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* Ticker Tape */
.ticker-row {
  font-family: "Arimo";
  font-weight: bolder;
  font-size: 1.2em;
  color:darkgray;
}

@media only screen and (max-width: 1400px) {
  .ticker-row{
    font-size: 1em;
  }
}

@media only screen and (max-width: 1250px) {
  .ticker-row{
    font-size: .9em;
  }
}

@media only screen and (max-width: 1175px) {
  .ticker-row{
    font-size: .8em;
  }
}

@media only screen and (max-width: 1035px) {
  .ticker-row{
    font-size: .9em;
  }
}

.widget-container {
  display: flex;
  flex-wrap: wrap;
}

.widget-container span {
  flex: 1;
  margin: 5px;
}

.widget > div:first-child {
  width: 100%;
  position: absolute;
  z-index: 999;
}

.watchlist-container {
  max-width: 200px;
}

@media only screen and (max-width: 950px) {
  .ticker-row{
    font-size: .8em;
  }
}

@media only screen and (max-width: 845px) {
  .ticker-row{
    font-size: .8em;
  }
}

@media only screen and (max-width: 768px) {
  .ticker-row{
    font-size: .8em;
  }
}
