#sidebar {
  position: relative;
  width: 65px;
  padding: 0px;
  /* height: 100%; */
}
#sidebar .pro-sidebar {
  height: 100%;
  width: 220px;
}
#sidebar .closemenu .pro-icon-wrapper {
  font-size: 25px !important;
  background-color: transparent !important;
}
#sidebar .pro-menu-item .pro-inner-item:hover .pro-icon-wrapper .pro-icon {
  animation: none !important;
}
#sidebar .pro-sidebar {
  width: 100%;
  min-width: 100%;
}

#sidebar .pro-sidebar-layout {
  background-color: black !important;
}

#sidebar .pro-sidebar-layout:hover {
  width: 185px;
}
.logo-text {
  display: none;
}
#sidebar .pro-sidebar-layout:hover .logo-text {
  display: inline-block;
  font-family: 'Anita';
  font-weight: bold;
}
#sidebar .pro-sidebar-layout:hover .logo {
  display: inline-block;
  margin: 0px 10px;
}

#sidebar .pro-sidebar.collapsed {
  width: 60px;
  min-width: 60px;
}
#sidebar .pro-sidebar-inner {
  background-color: white;
  box-shadow: 0.5px 0.866px 2px 0px rgba(0, 0, 0, 0.15);
}
#sidebar .pro-sidebar-inner .pro-sidebar-layout {
  overflow-y: hidden;
}
#sidebar .pro-sidebar-inner .pro-sidebar-layout .logotext p {
  font-size: 25px;
  padding: 0 20px;
  color: #dde1fd;
  font-weight: bold;
}
#sidebar .pro-sidebar-inner .pro-sidebar-layout ul {
  /*padding: 0 5px;*/
}
#sidebar .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item {
  color: #dde1fd;
  margin: 5px 0px;
  font-weight: bold;
  padding: 8px 15px 8px;
}
#sidebar .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item .pro-icon-wrapper {
  font-size: 25px;
  background-color: transparent;
  color: #dde1fd;
  border-radius: 3px;
}
#sidebar
  .pro-sidebar-inner
  .pro-sidebar-layout
  ul
  .pro-inner-item
  .pro-icon-wrapper
  .pro-item-content {
  color: #dde1fd;
}
#sidebar .pro-sidebar-inner .pro-sidebar-layout .active {
  background-image: linear-gradient(0deg, #ce3aff 0%, #4752ff 60%);
}
#sidebar .logo {
  padding: 5px 0px;
  margin: 0px auto;
  display: block;
  height: 55px;
}

/* @media only screen and (max-width: 720px) {
    html {
        overflow: hidden;
    }
} */
