.profile-image {
    border-radius: 50%;
}

.profile-image-container {
    width: min-content;
    margin-top: 25px;
    margin-bottom: 25px;
}

.edit-container {
    width: 40px;
    height: 40px;
    right: 0;
    cursor: pointer;
}
.profile-image {
    width: 150px;
    height: 150px;   
}

.page{
    margin-top: 100px;
    font-family: "Arimo";
}

h5{
    font-weight: bold;
}

.form-label{
    font-weight: bold;
}

.btn {
    margin: 5px;
    display:flex;
    
}