.Landing {
  text-align: center;
}

.Landing-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .Landing-logo {
    animation: Landing-logo-spin infinite 20s linear;
  }
}

.Landing-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.Landing-link {
  color: #61dafb;
}

@keyframes Landing-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.mobile-nav {
  inset: 0;
  width: 100vw;
  height: 100vh;
  background: #050834;
  display: block !important;
  position: absolute;
  // display: flex;

  .navbar-nav {
    display: flex;
    // height: 80%;
    flex-direction: column;
    margin-top: 100px;
    justify-content: center;
    height: calc( 100% - 100px );

    &::before {
      content: '';
      height: 4px;
      width: 100%;
      position: absolute;
      top: 100px;
      left: 0;
      right: 0;
      background: #4752FF;
    }

    .nav-link {
      color: white !important;
      font-size: 20px;
      font-weight: bold;
      margin-top: 40px;
    }
  }
}