@font-face {
  font-family: "Arimo";
  src: url("/src/Fonts/Arimo/Arimo-VariableFont_wght.ttf") format('truetype');
}

@font-face {
  font-family: "Anita";
  src: url("/src/Fonts/Anita Semi-Square/Anita semi square.ttf") format('truetype');
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.pro-menu-item {
  padding: 0px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.main-col {
  height: 100vh;
  max-height: 100%;
  min-height: 100vh;
  min-width: 50px;
  overflow: scroll;
  overflow-x: hidden;
  background-color: black;
}

.main-col > div {
  min-height: calc(100vh - 65px);
  background: black;
}

.close-icon {
  font-size: 25px;
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
}

.logo {
  height: 100px;
  width: auto;
}

.cursor-pointer {
  cursor: pointer;
}

svg.h4.mb-0{
  font-size: .5em;
}

.aa-Panel {
  z-index: 9999;
}
