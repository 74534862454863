.JTKAdmin {
  text-align: center;
}

.JTKAdmin-logo {
  height: 40vmin;
  pointer-events: none;
}

.JTKAdmin-header {
  background-color: #0e0e11e7;
  min-height: 65px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.JTKAdmin-link {
  color: #61dafb;
}

@keyframes JTKAdmin-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ticker-row {
  min-height: 50px;
  justify-content: center;
  line-height: 50px;
}

.ticker-row div {
  display: flex;
  justify-content: space-between;
}
