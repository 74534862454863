.feature-card {

    &>*,
    & {
        transition: all 150ms;
    }

    &:hover, &.touch  {
        background: white;

        h1 {
            color: black !important;
        }

        p {
            color: #666666 !important;
        }

        button {
            opacity: 1;
        }
    }
    button {
        opacity: 0;
    }

}