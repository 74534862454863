span{
    font-family: 'Arimo';
    font-size: .9em;
}

.list-group-item{
    /* background-color: black;
    color: white; */
}

.list-group-item{
    text-align: center;
}

.market-movers-ticker-currentprice{
    display: inline-flex;
    flex-direction: column;
}

.market-movers-change{
    display: inline-flex;
    flex-direction: column;
}

.arrow{
    font-size: x-large;
}
