.message-view {

    .message-options {
        opacity: 0;
    }



    &:hover {
        .message-options {
            opacity: 1;
        }
    }

    .chat-profile-popover {

        min-width: 250px;

        .header {
            margin-bottom: 20px;
            height: 60px;
            position: relative;

            .avatar {

                position: absolute;
                width: max-content;
                top: 100%;
                transform: translateY(-50%);



                .indicator {
                    position: absolute;
                    top: 80%;
                    left: 80%;

                    &::after {
                        content: '';
                        width: 10px;
                        height: 10px;
                        background: green;
                        position: absolute;
                        border-radius: 50%;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);

                    }

                    &::before {
                        content: '';
                        width: 15px;
                        height: 15px;
                        background: white;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        border-radius: 50%;
                    }
                }

                img {
                    width: 60px;
                    height: 60px;
                    object-fit: cover;
                    // transform: translateY(50%);
                }
            }
        }

    }
}