.post-container {
    .layout {
        display: grid;
        gap: 1rem;

        grid-template-columns: min-content 1fr min-content;
    }

    .imgContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50px;
        height: 50px;
        border-radius: 50px;
        overflow: hidden;
    }

    .user-img {
        width: 100%;
        height: 100%;
        cursor: pointer;
    }

    .post-container {
        display: flex;
        height: 100%;
        flex-direction: column;
    }

    .user-details {
        display: flex;
        align-items: baseline;
        gap: 10px;
    }

    .user-details p {
        font-weight: bold;
        color: black;
        margin: 0;
    }

    .user-details span {
        font-weight: 400;
        color: grey;
    }

    .post {
        margin-top: 0.5rem;
    }

    .post-container p {
        margin: 0;
    }

    .post-img-container {
        width: 100%;
        height: 300px;
        border-radius: 20px;
        overflow: hidden;
        margin-top: 1rem;
    }

    .post-img {
        width: 100%;
        height: 100%;
    }

    .icon-container {
        display: flex;
        margin-top: 1rem;
        align-items: center;
        justify-content: space-around;
    }

    .icon {
        display: flex;
        align-items: center;
        gap: 1rem;
        cursor: pointer;
    }

    .menu {
        cursor: pointer;
    }


    .comment-view {
        flex: 1;
        text-align: left;

        img {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            object-fit: cover;
        }
    }

}