.chat-widget {
    // @media only screen and (max-width: 600px) {
    //     .sidebar-rooms{
    //         display: none;
    //     }

    //     .back-btn{
    //         display: block;
    //     }
    // }

    // @media only screen and (min-width: 600px) {
    //     .sidebar-rooms{
    //         display: block;
    //     }

    //     .back-btn{
    //         display: none;
    //     }
    // }

    // &.sidebar-open{
    //     .sidebar-rooms{
    //         display: block;
    //         flex: 1;
    //     }

    //     .messenger{
    //         display: none !important;
    //     }
    // }
}

.header-container {
    background: #26272b;
    color: white;
}

.sentMessage-container {
    background: #40444b;
    margin: 10px;
    border-radius: 10px;
}

.message-input {

    color: white;
    padding: 10px;
    max-height: 200px;
    overflow-y: auto;

    &[contenteditable]:empty::before {
        content: "messsage...";
        color: rgb(138, 138, 138);
    }

    &:focus {
        outline: none;
    }
}

.sent-button {
    background-color: #4752ff !important;
}

.sent-button>svg {
    color: white;
}

// .textarea