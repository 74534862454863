.flex-1 {
    flex: 1;
}

.cursor-pointer {
    cursor: pointer;
}

.transition-300{
    transition: all 300ms;
}

.opacity-50\:active {
    &:active {
        opacity: .5;
    }
}

.select-none {
    user-select: none;
}

.profile-active {

    &.profile-image {
        width: 30px;
        height: 30px;
        border-radius: 50%;

        img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            object-fit: cover;
        }
    }

    border: 5px solid #28ff30;
    border-radius: 50%;
    margin-right: 6px;

    &.small {
        border-width: 3px !important;
    }

}

.bg {

    &-dot {
        background: url(../../assets/svgs/circle.svg);
        background-repeat: repeat;
        background-size: 40px;
    }

    &-square {
        background: url(../../assets/svgs/square.svg);
        background-repeat: repeat;
        background-size: 60px;
    }

    &-blue {
        &-deep {
            background-color: #050834;
        }

        &-light {
            background-color: #161842;
        }
    }
}

.text {
    &-6xl {
        font-size: 64px;


    }

    &-5xl {
        font-size: 48px;
    }

    &-2xl {
        font-size: 32px;
    }

    &-gray {
        &-base {
            color: #666666;
        }

        &-light {
            color: #9B9CAE;
        }
    }
}

.lg\:text-6xl {
    @media only screen and (min-width: 1024px) {
        font-size: 60px;
    }
}

.outline {
    &-none {
        outline: none;
    }
}

.w {
    &-max {
        width: max-content;
    }

    &-inherit {
        width: inherit;
    }

    &-vw-100 {
        width: 100vw;
    }
}

.leading-8 {
    line-height: 2rem;
}

.container {

    &-half {
        width: 660px;
    }

    &-lg-half {
        width: 100%;

        @media only screen and (min-width: 992px) {
            width: 480px;
        }

        @media only screen and (min-width: 1200px) {
            width: 570px;
            // width: 660px;
        }

        @media only screen and (min-width: 1400px) {
            width: 660px;
        }
    }


}

.sticky{
    position: fixed;
    transition: all 300ms;
    background: #050834;
    color: white;
    // position: relative;

    
    &::before{
        content: '';
        height: 4px;
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background: #4752FF;
    }

    @media only screen and (min-width: 992px) {
    
        .navbar-nav{
            .nav-link{
                color: white !important;
                opacity: .6;
               
    
                &:hover{
                    color: white;
                    opacity: 1;
                }
            }
        }
    }
}

.z-99{
    z-index: 99;
}